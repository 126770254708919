import { Injectable } from '@angular/core';
import { RouteKeys } from '../helper/route-keys.helper';
import { ApiService } from './api.service';
import { ConfigService } from './config.service';
import { StorageService } from './storage.service';
import { UrlService } from './url.service';

@Injectable()
export class AuthService {
  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private configService: ConfigService,
    private urlService: UrlService
  ) {}

  login(benutzername: string, passwort: string, okCb: () => void, errCb: (err: any) => void) {
    this.apiService.post<any>(RouteKeys.Auth.get_token, { username: benutzername, password: passwort }).subscribe({
      next: (response) => {
		this.storageService.clearFromLocale('inquire'); // um die in fr�heren Versionen nicht korrekt gespeicherten Daten zu l�schen
        this.storageService.saveToSession('benutzername', response.benutzername);
        this.storageService.saveToSession('token', response.token);
        this.storageService.saveToSession('rollen', response.rollen);
        okCb();
      },
      error: (err) => {
        errCb(err);
      },
    });
  }

  logout() {
    this.storageService.clearSession();
    this.urlService.naviToPreserveQueryParams('extern');
  }

  isLoggedIn(): boolean {
    const token = this.storageService.getFromSession<string>('token');
    const benutzername = this.storageService.getFromSession<string>('benutzername');
    if (token && token.length && benutzername && benutzername.length) {
      return true;
    }

    return false;
  }

  getBenutzerName(): string {
    return this.storageService.getFromSession<string>('benutzername');
  }

  // isInRole(role: string = null): boolean {
  //   const _role = this.getRole();
  //   if (_role.toLocaleLowerCase() === 'administrator') {
  //     return true;
  //   }
  //   return _role.toLocaleLowerCase() === role.toLocaleLowerCase();
  // }

  isInRole(role: string = null): boolean {
    const _roles = this.getRoles();
    let result = false;
    _roles.forEach((_role) => {
      const exist = this.configService.rollen.find((x) => x.id === _role);
      if (exist !== undefined && exist !== null) {
        if (exist.bezeichnung.toLocaleLowerCase() === role.toLocaleLowerCase()) {
          result = true;
        }
      }
    });

    return result;
  }

  isInOneOfThisRoles(roles: string[]): boolean {
    let result = false;
    roles.forEach((r) => {
      if (this.isInRole(r)) {
        result = true;
      }
    });

    return result;
  }

  // getRole(): string {
  //   return this.storageService.getFromSession<string>('rolle');
  // }
  getRoles(): number[] {
    return this.storageService.getFromSession<number[]>('rollen');
  }
}
