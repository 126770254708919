import { Component, ViewChild, OnDestroy } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { Subscription } from 'rxjs';
import { PopupService } from '../../services/popup.service';
import { ApiService } from 'src/app/share/services/api.service';
import { UrlService } from 'src/app/share/services/url.service';
import { RouteKeys } from 'src/app/share/helper/route-keys.helper';
import { LanguageService } from '../../services/language.service';
import { DxFormComponent } from 'devextreme-angular';

@Component({
  selector: 'app-add-institution',
  templateUrl: './add-institution.component.html',
  styleUrls: ['./add-institution.component.scss'],
})
export class AddInstitutionComponent implements OnDestroy {
  @ViewChild('addInstitutionPopup', { static: false }) addInstitutionPopup: DxPopupComponent;
  @ViewChild('form', { static: false }) form: DxFormComponent;

  subscription_get_PopupAsObservable: Subscription;
  subscription_get_externSelectedIdsQueryParams: Subscription;
  institutionList: any[] = [];
  selectedIds: number[] = [];
  formData: any = {
    id: null,
  };
  selectedLanguageObservable: Subscription;

  constructor(
    private popupService: PopupService,
    private apiService: ApiService,
    private urlService: UrlService,
    private languageService: LanguageService
  ) {
    this.onCloseClick = this.onCloseClick.bind(this);
    this.onSelectClick = this.onSelectClick.bind(this);

    this.subscription_get_externSelectedIdsQueryParams = this.urlService.get_externSelectedIdsQueryParams.subscribe((ids) => {
      if (ids !== null && ids !== undefined && ids.length > 0) {
        this.selectedIds = ids;
      } else {
        this.selectedIds = [];
      }
    });

    this.selectedLanguageObservable = languageService.get_selectedLanguageObservable.subscribe((lang) => {
      if (this.form !== undefined) {
        this.form.instance.repaint();
      }
    });

    this.subscription_get_PopupAsObservable = this.popupService.get_addInstitutionPopupAsObservable.subscribe((context) => {
      if (context !== null && context !== undefined && context.open === true && this.addInstitutionPopup !== undefined) {
        this.addInstitutionPopup.instance.show();

        this.apiService.post<any>(RouteKeys.Extern.get_search, this.formData).subscribe({
          next: (response) => {
            this.institutionList = response.filter((x) => x.isSelectable === true);
            for (let index = 0; index < this.selectedIds.length; index++) {
              const id = this.selectedIds[index];
              const res = this.institutionList.find((x) => x.id === id);
              res.visible = false;
            }

            for (let i = 0; i < this.institutionList.length; i++) {
              this.institutionList[i].adresse =
                this.institutionList[i].einrichtungsName +
                ' - ' +
                this.institutionList[i].strasse +
                ' ' +
                this.institutionList[i].hnr +
                (this.institutionList[i].hnrZusatz !== null && this.institutionList[i].hnrZusatz.length > 0
                  ? this.institutionList[i].hnrZusatz
                  : '') +
                ', ' +
                this.institutionList[i].plz +
                ' ' +
                this.institutionList[i].ort;
            }
          },
          error: (err) => {
            throw err;
          },
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription_get_externSelectedIdsQueryParams) {
      this.subscription_get_externSelectedIdsQueryParams.unsubscribe();
    }
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
    if (this.selectedLanguageObservable) {
      this.selectedLanguageObservable.unsubscribe();
    }
  }

  onSelectClick() {
    if (this.formData !== null && this.formData !== undefined && this.formData.id !== null && this.formData.id !== undefined) {
      this.selectedIds.push(this.formData.id);

      this.urlService
        .refreshQueryParams({
          i: this.selectedIds.join(','),
        })
        .then(() => {
          this.addInstitutionPopup.instance.hide();
        });
    }
  }

  onCloseClick() {
    this.addInstitutionPopup.instance.hide();
  }

  onHidden(e) {
    this.formData.id = null;
  }
}
