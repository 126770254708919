import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { Subscription } from 'rxjs';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';
import { LanguageService } from '../../services/language.service';
import { PopupService } from '../../services/popup.service';
import { StorageService } from '../../services/storage.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-remove-account',
  templateUrl: './remove-account.component.html',
  styleUrls: ['./remove-account.component.scss'],
})
export class RemoveAccountComponent implements OnInit, OnDestroy {
  @ViewChild('removeAccountPopup', { static: false }) removeAccountPopup: DxPopupComponent;
  @ViewChild('deleteForm', { static: false }) deleteForm: DxFormComponent;

  msg: string = '';
  messageTimeout: number;
  isRemoved: boolean = false;
  showDeletePopup: boolean;
  deleteFormData: any = {
    password: '',
  };
  privacy_delete: string;

  subscription_get_PopupAsObservable: Subscription;
  selectedLanguageObservable: Subscription;

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private router: Router,
    private popupService: PopupService,
    private languageService: LanguageService
  ) {
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onDeleteAccountClick = this.onDeleteAccountClick.bind(this);
    this.onClickClose = this.onClickClose.bind(this);

    this.subscription_get_PopupAsObservable = this.popupService.get_removeAccountPopupAsObservable.subscribe((context) => {
      if (context !== null && context !== undefined && context.open === true) {
        this.removeAccountPopup.instance.show();
      }
    });
  }

  ngOnInit(): void {
    this.selectedLanguageObservable = this.languageService.get_selectedLanguageObservable.subscribe((lang) => {
      this.apiService.post<any>(RouteKeys.Extern.get_global_settings, { key: 'privacy_account_delete' + lang.trim() }).subscribe({
        next: (response) => {
          if (response.wert != null && response.wert != undefined && response.wert.trim() != '') {
            this.privacy_delete = response.wert;
          }
        },
        error: (err) => {
          this.privacy_delete = this.languageService.translate('privacy_delete');
        },
      });
    });
  }

  ngOnDestroy(): void {
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }

    if (this.selectedLanguageObservable) {
      this.selectedLanguageObservable.unsubscribe();
    }
  }

  onDeleteAccountClick() {
    this.showDeletePopup = true;
  }

  onClickDelete() {
    var validationResult = this.deleteForm.instance.validate();
    if (validationResult.isValid) {
      let result = confirm(this.languageService.translate('confirmRegulations'), this.languageService.translate('confirmation'));
      result.then((dialogResult) => {
        if (dialogResult) {
          this.apiService.post(RouteKeys.Intern.Buerger.remove_account, this.deleteFormData).subscribe({
            next: (response) => {
              this.storageService.clearFromSession('token');
              this.storageService.clearFromSession('rolle');
              this.storageService.clearFromSession('benutzername');
              this.isRemoved = true;
              this.msg = this.languageService.translate('deleteUserAccount');
              setTimeout(() => {
                this.router.navigate(['extern']);
                this.onClickClose();
              }, 4000);
            },
            error: (err) => {
              if (err !== null && err.status !== null && err.status === 400) {
                this.setMessageText(this.languageService.translate('incorrectPassword'));
              } else {
                this.setMessageText(err?.error?.message || err?.error?.Message || this.languageService.translate('laterError'));
              }
              throw err?.error?.message || err?.error?.Message || this.languageService.translate('serverError');
            },
          });
        }
      });
    }
  }

  onClickClose() {
    this.deleteForm.instance.resetValues();
    this.removeAccountPopup.instance.hide();
  }

  setMessageText(msg: string) {
    this.msg = msg;
    if (this.messageTimeout > 0) {
      clearTimeout(this.messageTimeout);
    }
    setTimeout(() => {
      this.msg = '';
    }, 5000);
  }
}
