import { Injectable } from '@angular/core';

/**
 * Der SysService mit denen man Systeminformationen abrufen kann.
 */

export interface ISysResult {
  browser: string;
  browserVersion: string;
  isMobile: boolean;
  language: string;
  languages: string[];
  platform: string;
  innerHeight: number;
  innerWidth: number;
  outerHeight: number;
  outerWidth: number;
  screenHeight: number;
  screenWidth: number;
  origin: string;
}

@Injectable()
export class SysService {
  constructor() {}

  /**
   * Gibt ein Objekt mit Systeminformationen zurück
   * @returns {ISysResult} Ein Objekt, das verschiedene Information über das aktuelle globale Fenster enthält (z. B Sprache, Browsername, Screen Größe)
   */
  public getSystemInformations(): ISysResult {
    return {
      browser: this._detectBrowserName(),
      browserVersion: this._detectBrowserVersion(),
      isMobile: this._isMobile(),
      language: window.navigator.language,
      languages: window.navigator.languages,
      platform: this._getOs(),
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
      outerHeight: window.outerHeight,
      outerWidth: window.outerWidth,
      origin: window.origin,
      screenHeight: window.screen.availHeight,
      screenWidth: window.screen.availWidth,
    } as ISysResult;
  }

  private _isMobile(): boolean {
    let _hasTouchScreen: boolean = false;

    const _navigator: any = window.navigator;

    if ('maxTouchPoints' in _navigator) {
      _hasTouchScreen = _navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in _navigator) {
      _hasTouchScreen = _navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia('(pointer:coarse)');

      if (mQ && mQ.media === '(pointer:coarse)') {
        _hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
        _hasTouchScreen = true;
      } else {
        const UA = navigator.userAgent;
        _hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }

    return _hasTouchScreen;
  }

  private _getOs(): string {
    if (/windows/i.test(navigator.userAgent)) {
      return 'Windows';
    } else if (/iphone/i.test(navigator.userAgent)) {
      return 'iOS';
    } else if (/ipad/i.test(navigator.userAgent)) {
      return 'iOS';
    } else if (/macintosh/i.test(navigator.userAgent)) {
      return 'Mac OS';
    } else {
      return 'unknown';
    }
  }

  private _detectBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  private _detectBrowserVersion(): string {
    const userAgent = navigator.userAgent;
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let tem;
    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edg)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
      matchTest.splice(1, 1, tem[1]);
    }
    return matchTest.join(' ');
  }
}
